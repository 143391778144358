import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import UserModel from '@uz/unitz-models/UserModel';
import useObservableSource from '@vl/hooks/useObservableSource';
import withLoadingPromise from '@vl/hooks/withLoadingPromise';
import i18n from 'i18n-js';
import firebase from 'gatsby-plugin-firebase';
import { loader } from '@uz/mod-translations/loader';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            currentUserModel: hook((ctx) => {
              const currentUser = ctx.get('authModel.currentUser', {});
              const [userProfile, $userProfile] = React.useState(null);
              const [activeLanguage, $activeLanguage] = React.useState('');
              const userId = _.get(currentUser, 'uid');
              const ref = React.useRef({});

              _.assign(ref.current, {
                currentUser,
                userProfile,
                $userProfile,
                userId,
                activeLanguage,
                $activeLanguage,
                ...withLoadingPromise('inited'),
              });

              const userSubscribe = useObservableSource(
                () =>
                  UserModel.find(
                    `where: {id: {_eq: "${userId}"}}`,
                    `profile {
                      display_name
                      phone_number
                    }`
                  ),
                userId
              );

              React.useEffect(() => {
                const subs = userSubscribe.subscribe((item) => {
                  const profile = _.get(item, '0.profile', {});
                  if (!_.isEqual(ref.current.userProfile, profile)) {
                    ref.current.$userProfile && ref.current.$userProfile(profile);
                  }
                  if (_.keys(profile).length) {
                    ref.current.inited.resolve(true);
                  }
                });
                return () => {
                  subs.unsubscribe();
                };
                // eslint-disable-next-line
              }, [userId]);

              React.useEffect(() => () => (ref.current = {}), []);

              const currentUserModel = {
                currentUser,
                activeLanguage: ref.current.activeLanguage,
                updateLanguage: async (lang) => {
                  await loader.get(lang);
                  ref.current.$activeLanguage(lang);
                  if (userId) {
                    await firebase
                      .firestore()
                      .collection('appconfigs')
                      .doc(userId)
                      .set({
                        lang,
                      });
                  }
                  i18n.locale = lang;
                  localStorage.setItem('lang', lang);
                },
                getUserId: () => {
                  return _.get(ref.current.currentUser, 'uid');
                },
                getDisplayName: (def) => {
                  return _.get(ref.current.userProfile, 'display_name', def || '');
                },
                getPhoneNumber: (def) => {
                  return _.get(ref.current.userProfile, 'phone_number', def || '');
                },
                getAvatarUrl: () => {
                  return _.get(ref.current.currentUser, 'photoURL', '');
                },
                checkReady: () => {
                  return ref.current.inited.check();
                }
              };
              ctx.apply('REF.setRef', 'currentUserModel', currentUserModel);
              return currentUserModel;
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
